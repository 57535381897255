import React from "react"

import Layout from "../../components/Layout"
import HeadSection from "../../components/HeadSection"
import Header from "../../components/Home/Header"
import CatalogSaj from "../../components/CatalogSaj"
import AdvantagesSection from "../../components/Home/AdvantagesSection"
import OfferSection from "../../components/Home/OfferSection"

import { sajCars } from "../../components/CatalogSaj/data"

const CatalogMetalPage = () => {
  return (
    <>
      <HeadSection
        title="Цены на сажевые фильтры"
        description="Каталог сажевые фильтры, которые мы выкупили у частных клиентов. Цены на них и данные по автомобилю, являющегося донором фильтры."
      />
      <Layout>
        <div className="screen">
          <Header />
        </div>
        <CatalogSaj data={sajCars} />
        <AdvantagesSection />
        <OfferSection />
      </Layout>
    </>
  )
}

export default CatalogMetalPage
